import React, { useRef } from "react"
import { graphql, navigate } from "gatsby"
import { useMutation } from "@apollo/react-hooks"
import gql from "graphql-tag"
import { createCharacter } from "../graphql/mutations"
import SEO from "../components/seo"
import { withLayout } from "../components/authenticated-layout"
import ErrorPanel from "../components/error-panel"

const CREATE_CHARACTER = gql(createCharacter)

export function CreateCharacter({ data }) {
  const nameRef = useRef()
  const roleRef = useRef()
  const [create, { error, loading }] = useMutation(CREATE_CHARACTER)

  async function handleSubmit(e) {
    e.preventDefault()

    const input = { name: nameRef.current.value, role: roleRef.current.value }
    await create({ variables: { input } })
    navigate("/characters")
  }

  return (
    <>
      <SEO title="Create Character" />
      <form onSubmit={handleSubmit}>
        <h1 className="text-2xl">Create Character</h1>
        <ErrorPanel error={error} />

        <div className="mt-4">
          <label htmlFor="name">Name</label>
          <input
            id="name"
            ref={nameRef}
            type="text"
            className="block"
            required
            autoFocus
          />
        </div>

        <div className="mt-4">
          <label htmlFor="role">Role</label>
          <input
            id="role"
            ref={roleRef}
            type="text"
            className="block"
            required
            list="roles"
          />
          <datalist id="roles">
            {data.site.siteMetadata.characters.roles.map(role => (
              <option key={role} value={role} />
            ))}
          </datalist>
        </div>

        <div className="mt-4">
          <button
            className="border rounded p-2 w-32 hover:text-black hover:bg-white"
            disabled={loading}
          >
            Save
          </button>
        </div>
      </form>
    </>
  )
}

export default withLayout(CreateCharacter)

export const query = graphql`
  query CreateCharacter {
    site {
      siteMetadata {
        characters {
          roles
        }
      }
    }
  }
`
